<template>
    <div class="AwardCardEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small" label-width="85px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="会员卡类型">
                            <el-input placeholder="" v-model="form.cardTypeName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构组">
                            <el-input placeholder="" v-model="form.deptGroupName" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="save" size="small">保存</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border :data="tableData">
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="会员卡号" width="240" prop="cardPrintNo">
                    <template slot-scope="scope"><el-input v-model="scope.row.cardPrintNo" size="mini" /> </template>
                </el-table-column>
                <el-table-column label="发卡状态" width="160" prop="status">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.status | status }}</span></template
                    >
                </el-table-column>
                <el-table-column label="操作" width="75" header-align="center">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="danger"
                            @click="deleteRow(scope.$index, tableData, scope.row)"
                            :disabled="scope.row.status != 0 ? true : false"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'AwardCardEdit',
    props: ['form'],
    data() {
        return {
            tableData: [],
            card: {
                delCodes: [],
            },

            url: {
                save: '/member/awardCard/patchExtend',
            },
        };
    },
    filters: {
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '0') {
                return '待售';
            }
            if (status == '1') {
                return '正常';
            }
            if (status == '2') {
                return '丢失';
            }
            if (status == '3') {
                return '作废';
            }
        },
    },
    mounted() {
        UrlUtils.QueryRemote(this, '/member/awardCard/info_c/' + this.form.code, (data) => {
            this.tableData = data.memberCardDos;
        });
    },
    methods: {
        save() {
            const _this = this;

            if (this.tableData.filter((r) => !r.cardPrintNo).length) {
                _this.$message.error('请填写所有的会员卡号');
                return;
            }

            const _params = {
                deptGroupCode: _this.form.deptGroupCode,
                cardType: _this.form.cardType,
                code: _this.form.code,
                //保存的数据
                memberCards: _this.tableData.map((d) => d.cardPrintNo),
                codes: _this.tableData.map((d) => d.code),
                chipCards: _this.tableData.map((d) => ' '),
                //删除的数据
                delCodes: _this.card.delCodes,
            };

            UrlUtils.PatchRemote(this, this.url.save, _params, null, () => {
                this.$message.success('保存成功');
                this.goBackAndReload();
            });
        },
        deleteRow(index, rows, row) {
            if (this.tableData.length == 1) {
                this.$message.error('不能删除所有卡');
                return;
            }
            this.card.delCodes.push(rows[index].code);
            rows.splice(index, 1);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
